import React from 'react';
import Logo from '../images/logo.svg';
import { BsArrowRight } from 'react-icons/bs';
import { animated } from 'react-spring';

const HomePage = ({ onHandleShowUserType, style }) => {
  return (
    <animated.div
      className='bg-white p-2 rounded-2xl border border-gray-300 shadow-md'
      style={{ width: '660px', height: '472px', ...style }}
    >
      <div className='text-center'>
        <img src={Logo} alt='Logo' className='mx-auto' />
      </div>

      <h1
        className='text-5xl font-bold text-black text-center'
        style={{ color: '#25272C', lineHeight: '52.8px' }}
      >
        All-Risk Sigorta Primini Hemen Hesapla!
      </h1>

      <div style={{ padding: '1.7rem' }}>
        <p
          className='text-xl font-thin text-center text-default'
          style={{
            fontSize: '20px',
            lineHeight: '36px',
            letterSpacing: '0em',
            textAlign: 'center',
          }}
        >
          Yeni kurulacak veya işletme dönemindeki güneş enerjisi santraliniz
          için sigorta primini hemen hesaplayın.
        </p>
      </div>

      <div className='flex justify-center items-center'>
        <button
          className='text-white font-medium py-4 px-8
            rounded-lg focus:outline-none focus:shadow-outline-blue 
            active:bg-blue-800'
          style={{ backgroundColor: '#003769', width: '95%' }}
          onClick={onHandleShowUserType}
        >
          <p
            style={{
              fontSize: '20px',
              lineHeight: '24px',
              letterSpacing: '0.05em',
            }}
          >
            Hesaplamaya başla <BsArrowRight className='inline-block' />
          </p>
        </button>
      </div>
    </animated.div>
  );
};

export default HomePage;

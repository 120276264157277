import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import Construction from '../images/construction.svg';
import Factory from '../images/factory.svg';
import { BsArrowRightShort } from 'react-icons/bs';

const UserType = ({ onHandleShowAddressSteps, onHandleOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const pageProps = useSpring({
    opacity: 1,
    transform: 'translate3d(0,0,0)',
    from: { opacity: 0, transform: 'translate3d(0,10px,0)' },
    config: {
      duration: 300,
      tension: 170,
      friction: 26,
    },
  });

  const handleOptionClick = (option) => {
    setSelectedOption((prevOption) => (option === prevOption ? null : option));
    onHandleOptionChange(option);
  };

  const renderSelection = (image, text, option) => (
    <div
      className={`bg-white p-4 rounded-lg border border-gray-300 shadow-md ${
        selectedOption === option ? 'bg-gray-300' : ''
      }`}
      style={{
        width: '294px',
        height: '216px',
        cursor: 'pointer',
        position: 'relative',
      }}
      onClick={() => handleOptionClick(option)}
    >
      <div className='text-center mt-8 mb-4'>
        <img
          src={image}
          alt={text}
          className='mx-auto'
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
      <p
        className='text-center font-bold text-default font-sans'
        style={{
          fontSize: '20px',
          lineHeight: '22px',
        }}
      >
        {text}
      </p>
      <div
        className={`radio-circle ${
          selectedOption === option ? 'selected' : ''
        }`}
        onClick={() => handleOptionClick(option)}
        style={{
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          backgroundColor: selectedOption === option ? '#003769' : 'white',
          border: `2px solid ${
            selectedOption === option ? 'white' : '#003769'
          }`,
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      />
    </div>
  );

  return (
    <animated.div
      className='bg-white p-4 rounded-2xl border border-gray-300 shadow-md'
      style={{ width: '660px', height: '430px', ...pageProps }}
    >
      <h5
        className='font-black text-default mb-4 text-center font-sans'
        style={{ fontSize: '32px' }}
      >
        Lütfen sigortalamak istediğiniz GES tipini belirtin.
      </h5>
      <div className='flex justify-center items-center gap-6'>
        {renderSelection(
          Construction,
          'GES kurulacak inşaat halindeki tesis',
          'construction'
        )}
        {renderSelection(
          Factory,
          'GES kurulu işletme dönemindeki tesis',
          'factory'
        )}
      </div>
      <button
        className={`flex justify-center items-center
        ml-auto mr-2
        text-white font-medium pl-6 mt-5 transition-all
        rounded-lg focus:outline-none focus:shadow-outline-blue 
        active:bg-blue-800 ${!selectedOption && "opacity-50 cursor-not-allowed"}`}
        style={{ backgroundColor: '#003769', width: '110px', height: '48px' }}
        onClick={onHandleShowAddressSteps}
      >
        <div className='flex justify-center items-center'>
          <p
            style={{
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.02em',
              marginRight: '1rem',
            }}
            className='font-sans'
          >
            İleri
          </p>{' '}
          <BsArrowRightShort
            style={{ width: '24px', height: '24px' }}
            className='inline-block'
          />
        </div>
      </button>
    </animated.div>
  );
};

export default UserType;

import React, { useState } from 'react';
import HomePage from './HomePage';
import UserType from './UserType';
import AddressSteps from './AdressSteps';
import { useSpring } from 'react-spring';
import Logo from '../images/logo.svg';
import ProgressBar from './progressBar';
import Finance from './Finance';
import LoadingScreen from './LoadingScreen';
import SummaryPage from './SummaryPage';
import calculateSummary from '../calculateSummary';

const App = () => {
  const [showUserType, setShowUserType] = useState(false);
  const [showAdressSteps, setShowAdressSteps] = useState(false);
  const [showFinance, setShowFinance] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [showSummaryPage, setShowSummaryPage] = useState(false);
  const [isHomePage, setIsHomePage] = useState(true);
  const [isMap, setIsMap] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [earthquakeCode, setEarthquakeCode] = useState(null);
  const [primData, setPrimData] = useState(null);

  const handleShowUserType = () => {
    setIsHomePage(false);
    setShowUserType(true);
    setShowAdressSteps(false);
    setProgress(1);
  };

  const handleShowAddressSteps = () => {
    setIsHomePage(false);
    setShowUserType(false);
    setShowAdressSteps(true);
    setProgress(2);
  };

  const handleShowAddressStepsMap = () => {
    setIsHomePage(false);
    setShowUserType(false);
    setShowAdressSteps(true);
    setIsMap(true);
    setProgress(2);
  };

  const handleShowFinance = (earthquakeCode) => {
    setIsHomePage(false);
    setShowUserType(false);
    setShowAdressSteps(false);
    setShowFinance(true);
    setProgress(3);
    setEarthquakeCode(earthquakeCode);
  };

  const handleShowLoadingPage = () => {
    setIsHomePage(false);
    setShowUserType(false);
    setShowAdressSteps(false);
    setShowFinance(false);
    setShowLoadingScreen(true);
  };

  const handleShowSummaryPage = () => {
    setIsHomePage(false);
    setShowUserType(false);
    setShowAdressSteps(false);
    setShowFinance(false);
    setShowLoadingScreen(false);
    setShowSummaryPage(true);
    setProgress(4);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const onHandleFinish = () => {
    setIsHomePage(true);
    setShowUserType(false);
    setShowAdressSteps(false);
    setShowFinance(false);
    setShowLoadingScreen(false);
    setShowSummaryPage(false);
    setProgress(0);
  }

  const handleCalculateSummaryPage = (facilityCost) => {
    const primData = calculateSummary(
      selectedOption,
      earthquakeCode,
      facilityCost
    );
    setPrimData(primData);
  };

  const props = useSpring({
    opacity: showUserType ? 0 : 1,
    transform: showUserType ? 'translate3d(0,-20px,0)' : 'translate3d(0,0,0)',
  });

  return (
    <div
      className='flex flex-col items-center justify-center'
      style={{ backgroundColor: '#F6F6F6', height: '900px' }}
    >
      {!isHomePage && (
        <div className='flex items-center absolute top-0  left-14'>
          <img src={Logo} alt='Logo' className='mx-auto w-80 ' />
          <ProgressBar progress={progress} />
        </div>
      )}
      {isHomePage ? (
        <HomePage onHandleShowUserType={handleShowUserType} style={props} />
      ) : showAdressSteps ? (
        <AddressSteps
          onHandleShowUserType={handleShowUserType}
          onHandleShowFinance={handleShowFinance}
          onHandleMap={handleShowAddressStepsMap}
          isMap={isMap}
        />
      ) : showUserType ? (
        <UserType
          onHandleShowAddressSteps={handleShowAddressSteps}
          onHandleOptionChange={handleOptionChange}
        />
      ) : showFinance ? (
        <Finance
          onHandleMap={handleShowAddressStepsMap}
          onhandleShowLoadingPage={handleShowLoadingPage}
          onHandleCalculateSummaryPage={handleCalculateSummaryPage}
        />
      ) : showLoadingScreen ? (
        <LoadingScreen onHandleShowSummaryPage={handleShowSummaryPage} />
      ) : showSummaryPage ? (
        <SummaryPage onHandleFinish={onHandleFinish} primData={primData} />
      ) : null}
    </div>
  );
};

export default App;

// src/components/ProgressBar.js
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const ProgressBar = ({ progress }) => {
  return (
    <div>
      <LinearProgress
        variant='determinate'
        value={(progress / 4) * 100}
        style={{
          height: '8px',
          borderRadius: '10px',
          marginLeft: '-24px',
          minWidth: '900px',
        }}
        color='primary'
      />
    </div>
  );
};

export default ProgressBar;

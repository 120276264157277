import React from "react";

const SummaryPage = ({ primData, style, onHandleFinish }) => {
  const { netPrim1, netPrim2 } = primData;

  return (
    <div
      className="bg-white p-6 rounded-2xl border border-gray-300 shadow-md"
      style={{ width: "660px", height: "358px", ...style }}
    >
      <h3
        className="font-bold text-default mb-4 text-center font-sans"
        style={{ fontSize: "32px", lineHeight: "35.2px" }}
      >
        Hesaplamalar sonrasında en uygun sigorta poliçe primimiz
      </h3>
      <h5
        className="font-bold text-default mb-2 text-center font-sans"
        style={{
          fontSize: "48px",
          lineHeight: "52.8px",
          background:
            "linear-gradient(180deg, #003769 0%, #0093FF 100%), linear-gradient(0deg, #25272C, #25272C)",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        {netPrim1} - {netPrim2}$
      </h5>

      <h3
        className="font-bold text-default mb-4 text-center font-sans"
        style={{ fontSize: "32px", lineHeight: "35.2px" }}
      >
        arasında olacaktır.
      </h3>
        <div className="flex justify-center py-4">
        <button
          onClick={onHandleFinish}
          className="text-white font-medium py-4 px-8 w-full mx-8
            rounded-lg focus:outline-none focus:shadow-outline-blue 
          active:bg-blue-800 bg-[#003769] transition-all"
        >
          Bitir
        </button>
        </div>
    </div>
  );
};

export default SummaryPage;

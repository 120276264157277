import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs';
import OutlinedInput from '@mui/material/OutlinedInput';
import { BsGeoAlt } from 'react-icons/bs';
import { GoogleMap, Marker } from '@react-google-maps/api';
import getCordsForaddress from '../getCordsForAddress';
import cities from '../cities.json';
import { CircularProgress } from '@mui/material';

const AddressSteps = ({ onHandleShowUserType, onHandleShowFinance, isMap }) => {
  const [isMapPage, setIsMapPage] = useState(isMap);
  const [mapCenter, setMapCenter] = useState({
    lat: 39.9226785,
    lng: 32.8213404,
  });
  const [address, setAddress] = useState('');
  const [addressDetails, setAddressDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const pageProps = useSpring({
    opacity: 1,
    transform: 'translate3d(0,0,0)',
    from: { opacity: 0, transform: 'translate3d(0,10px,0)' },
    config: {
      duration: 300,
      tension: 170,
      friction: 26,
    },
  });

  const handleMapClick = async (address) => {
    const data = await getCordsForaddress(address);

    const { coordinates, addressDetails } = data;

    setAddressDetails(addressDetails);
    setMapCenter(coordinates);

    setIsMapPage(true);
  };

  const handleUseCurrentLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const addressData = await getCordsForaddress(
            `${latitude},${longitude}`
          );
          const { coordinates, addressDetails, adress } = addressData;

          setAddressDetails(addressDetails);
          setMapCenter(coordinates);
          setAddress(adress);
          setLoading(false);
        },
        (error) => {
          console.error('Error getting current location:', error.message);
          setLoading(false);
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser');
      setLoading(false);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      const data = await getCordsForaddress(address);

      const { coordinates, addressDetails } = data;

      setAddressDetails(addressDetails);
      setMapCenter(coordinates);

      setIsMapPage(true);
    }
  };

  const handleCityChange = (city) => {
    setAddressDetails({ ...addressDetails, city });
  };

  const handleTownChange = (town) => {
    setAddressDetails({ ...addressDetails, town });
  };

  const handleDistrictChange = (district) => {
    setAddressDetails({ ...addressDetails, district });
  };

  const handleFindEarthQuakeZone = () => {
    const { data } = cities;

    const { district, city } = addressDetails;

    const result = data.find((item) => {
      if (
        item.IL_ADI === city.toLocaleUpperCase('tr-TR') &&
        item.MAHALLE_ADI === district.toLocaleUpperCase('tr-TR')
      ) {
        return true;
      }
      return false;
    });

    // if (result) {
    //   console.log('DEPREMBOLGEKOD:', result.DEPREMBOLGEKOD);
    // } else {
    //   console.log('Data cannot be found');
    // }

    onHandleShowFinance(result.DEPREMBOLGEKOD);
  };

  return (
    <div>
      {!isMapPage ? (
        <animated.div
          key='adressPage'
          className='bg-white pl-12 pr-12 pt-4 rounded-2xl border border-gray-300 shadow-md'
          style={{
            width: '660px',
            height: '337px',
            ...pageProps,
          }}
        >
          <h5
            className='font-black text-default mb-16 text-center font-sans'
            style={{ fontSize: '32px' }}
          >
            Lütfen adres bilgilerinizi giriniz.
          </h5>
          <div className='grid grid-cols-3'>
            <div className='col-span-2'>
              <p
                className='font-thin text-default font-sans'
                style={{
                  fontSize: '16px',
                  fontWeight: 100,
                }}
              >
                Tam Adres (Sokak, Mahalle, Semt, İlçe vb...)
              </p>
              <OutlinedInput
                value={address || ''}
                onChange={(e) => setAddress(e.target.value)}
                placeholder='Tam Adres (Sokak, Mahalle vb...)'
                type='text'
                className='mt-2 font-thin'
                style={{
                  width: '357px',
                  height: '48px',
                  borderRadius: '8px',
                }}
              />
            </div>
            <div className='col-span-1'>
              <p className='mt-10'></p>

              <div
                className='flex justify-center items-center'
                style={{ cursor: 'pointer' }}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: '#0074DF', width: '24px', height: '24px' }}
                  />
                ) : (
                  <>
                    <BsGeoAlt
                      onClick={handleUseCurrentLocation}
                      style={{
                        width: '24px',
                        height: '32px',
                        marginRight: '12px',
                        marginLeft: '-1px',
                        color: '#0074DF',
                      }}
                    />
                    <p
                      onClick={handleUseCurrentLocation}
                      style={{
                        fontSize: '16px',
                        color: '#0074DF',
                        fontWeight: 600,
                      }}
                    >
                      Konumumu kullan
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='flex justify-between'>
            <button
              className='
          flex justify-center items-center font-medium  mt-14
          rounded-lg focus:outline-none focus:shadow-outline-blue'
              style={{
                fontSize: '16px',
                width: '110px',
                height: '48px',
                color: '#0074DF',
                fontWeight: 600,
              }}
              onClick={onHandleShowUserType}
            >
              <div className='flex justify-center items-center'>
                <BsArrowLeftShort
                  style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '1rem',
                  }}
                  className='inline-block'
                />
                <p
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.02em',
                    marginRight: '4rem',
                  }}
                  className='font-sans'
                >
                  Geri
                </p>
              </div>
            </button>
            <button
              className={`flex justify-center items-center
              -mr-7
              text-white font-medium  mt-14
              rounded-lg focus:outline-none focus:shadow-outline-blue 
              active:bg-blue-800 bg-[#003769] w-[145px] h-[48px] ${!address && 'opacity-50 cursor-not-allowed'}`}
              onClick={() => handleMapClick(address)}
            >
              <div className='flex justify-center items-center'>
                <p
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.02em',
                    marginLeft: '1rem',
                  }}
                  className='font-sans'
                >
                  İleri
                </p>{' '}
                <BsArrowRightShort
                  style={{
                    width: '24px',
                    height: '24px',
                    marginLeft: '1rem',
                  }}
                  className='inline-block'
                />
              </div>
            </button>
          </div>
        </animated.div>
      ) : (
        <animated.div
          key='mapPage'
          className='bg-white pl-12 pr-12 pt-4 mt-32  rounded-2xl border border-gray-300 shadow-md'
          style={{
            width: '660px',
            height: 'auto',
            ...pageProps,
          }}
        >
          <h5
            className='font-black text-default mb-16 text-center font-sans'
            style={{ fontSize: '32px' }}
          >
            Lütfen adres bilgilerinizi giriniz.
          </h5>
          <div className='grid grid-cols-3'>
            <div className='col-span-2'>
              <p
                className='font-thin text-default font-sans'
                style={{
                  fontSize: '16px',
                  fontWeight: 100,
                }}
              >
                Tam Adres (Sokak, Mahalle, Semt, İlçe vb...)
              </p>
              <OutlinedInput
                value={address || ''}
                onChange={(e) => setAddress(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder='Tam Adres (Sokak, Mahalle vb...)'
                type='text'
                className='mt-2 font-thin'
                style={{
                  width: '532px',
                  height: '48px',
                  borderRadius: '8px',
                }}
              />
            </div>
            <div className='col-span-1'>
              <p className='mt-10'></p>
              {loading ? (
                <CircularProgress
                  style={{
                    color: '#0074DF',
                    marginLeft: '180px',
                    marginTop: '6.5px',
                    width: '24px',
                    height: '24px',
                  }}
                />
              ) : (
                <BsGeoAlt
                  onClick={handleUseCurrentLocation}
                  style={{
                    width: '24px',
                    height: '32px',
                    marginLeft: '180px',
                    cursor: 'pointer',
                    color: '#0074DF',
                  }}
                />
              )}
            </div>
          </div>
          <div>
            <GoogleMap
              mapContainerStyle={{
                width: '580px',
                height: '300px',
                marginTop: '20px',
                marginBottom: '20px',
                borderRadius: '4px',
              }}
              center={mapCenter}
              zoom={16}
            >
              <Marker position={mapCenter} />
            </GoogleMap>
          </div>
          <div className='flex justify-between'>
            <div>
              <p>Mahalle</p>
              <OutlinedInput
                value={addressDetails.district || ''}
                onChange={(e) => handleDistrictChange(e.target.value)}
                style={{
                  marginTop: '4px',
                  marginRight: '32px',
                  width: '172px',
                  height: '48px',
                  borderRadius: '8px',
                }}
              />
            </div>
            <div>
              <p>İlçe</p>
              <OutlinedInput
                value={addressDetails.town || ''}
                onChange={(e) => handleTownChange(e.target.value)}
                style={{
                  marginTop: '4px',
                  marginRight: '32px',
                  width: '172px',
                  height: '48px',
                  borderRadius: '8px',
                }}
              />
            </div>
            <div>
              <p>İl</p>
              <OutlinedInput
                value={addressDetails.city || ''}
                onChange={(e) => handleCityChange(e.target.value)}
                style={{
                  marginTop: '4px',
                  marginRight: '32px',
                  width: '172px',
                  height: '48px',
                  borderRadius: '8px',
                }}
              />
            </div>
          </div>
          <div className='flex justify-between'>
            <button
              className='
          flex justify-center items-center font-medium  mt-14
          rounded-lg focus:outline-none focus:shadow-outline-blue'
              style={{
                fontSize: '16px',
                width: '110px',
                height: '48px',
                color: '#0074DF',
                fontWeight: 600,
              }}
              onClick={() => setIsMapPage(false)}
            >
              <div className='flex justify-center items-center'>
                <BsArrowLeftShort
                  style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '1rem',
                  }}
                  className='inline-block'
                />
                <p
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.02em',
                    marginRight: '4rem',
                  }}
                  className='font-sans'
                >
                  Geri
                </p>
              </div>
            </button>
            <button
              className='
          flex justify-center items-center
          -mr-7
          text-white font-medium  mt-14
          rounded-lg focus:outline-none focus:shadow-outline-blue 
          active:bg-blue-800'
              style={{
                backgroundColor: '#003769',
                width: '110px',
                height: '48px',
                marginBottom: '20px',
              }}
              onClick={() => handleFindEarthQuakeZone()}
            >
              <div className='flex justify-center items-center'>
                <p
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.02em',
                    marginLeft: '1rem',
                  }}
                  className='font-sans'
                >
                  İleri
                </p>{' '}
                <BsArrowRightShort
                  style={{
                    width: '24px',
                    height: '24px',
                    marginLeft: '1rem',
                  }}
                  className='inline-block'
                />
              </div>
            </button>
          </div>
        </animated.div>
      )}
    </div>
  );
};

export default AddressSteps;

import constructionData from './kurulum.json';
import factoryData from './isletme.json';

const calculateSummary = (selectedOption, earthquakeCode, facilityCost) => {
  const formattedCost = facilityCost.replace(/\./g, '');

  if (selectedOption === 'construction') {
    const { data } = constructionData;
    const earthQuakePrice = data.find(
      (item) => item['Deprem Bölgesi'] === earthquakeCode
    )['Deprem Fiyatı'];

    const timeAdjustedPrice = earthQuakePrice * 0.7;

    const carEar1 = data.find(
      (item) => item['Deprem Bölgesi'] === earthquakeCode
    )['CAR/EAR Fiyatı1'];
    const carEar2 = data.find(
      (item) => item['Deprem Bölgesi'] === earthquakeCode
    )['CAR/EAR Fiyatı2'];

    const totalPriceLower = timeAdjustedPrice + carEar1;
    const totalPriceUpper = timeAdjustedPrice + carEar2;

    const netPrim1 = (formattedCost / 1000) * totalPriceLower;
    const netPrim2 = (formattedCost / 1000) * totalPriceUpper;

    function formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return {
      netPrim1: formatNumber(Math.floor(netPrim1)),
      netPrim2: formatNumber(Math.floor(netPrim2)),
    };
  } else {
    const { data } = factoryData;
    const totalPrice1 = data.find(
      (item) => item['Deprem Bölgesi'] === earthquakeCode
    )['Toplam Fiyat1'];

    const totalPrice2 = data.find(
      (item) => item['Deprem Bölgesi'] === earthquakeCode
    )['Toplam Fiyat2'];

    const netPrim1 = (formattedCost / 1000) * totalPrice1;
    const netPrim2 = (formattedCost / 1000) * totalPrice2;

    function formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return {
      netPrim1: formatNumber(Math.floor(netPrim1)),
      netPrim2: formatNumber(Math.floor(netPrim2)),
    };
  }
};

export default calculateSummary;

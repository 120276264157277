import axios from 'axios';

const API_KEY = 'AIzaSyAfzu3WZlywETMoYYZfooSjQEjPEb5hj-c';

const findShortName = (data) => {
  const details = {
    district: '',
    city: '',
    town: '',
  };

  for (const component of data) {
    const types = component.types;

    if (types.includes('administrative_area_level_4')) {
      details.district = component.short_name;
    }

    if (types.includes('administrative_area_level_1')) {
      details.city = component.short_name;
    }

    if (types.includes('administrative_area_level_2')) {
      details.town = component.short_name;
    }
  }

  return details;
};

async function getCordsForAddress(address) {
  const response =
    await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${API_KEY}
  `);

  const data = response.data;

  const addressDetails = findShortName(data.results[0].address_components);
  const adress = data.results[0].formatted_address;

  const coordinates = data.results[0].geometry.location;

  return {
    coordinates,
    addressDetails,
    adress,
  };
}

export default getCordsForAddress;

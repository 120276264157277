import { FormControl } from '@mui/base';
import { OutlinedInput, Select, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs';
import { animated, useSpring } from 'react-spring';

const Finance = ({
  onHandleMap,
  onhandleShowLoadingPage,
  onHandleCalculateSummaryPage,
  style,
}) => {
  const [currency, setCurrency] = useState('dolar');
  const [facilityCost, setFacilityCost] = useState();

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleFacilityCostChange = ({ target }) => {
    const inputValue = target.value.replace(/\D/g, ''); // Sadece sayısal karakterleri al

    const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    setFacilityCost(formattedValue);
  };
  const handleFinancePage = () => {
    onhandleShowLoadingPage();
    onHandleCalculateSummaryPage(facilityCost);
  };

  const pageProps = useSpring({
    opacity: 1,
    transform: 'translate3d(0,0,0)',
    from: { opacity: 0, transform: 'translate3d(0,10px,0)' },
    config: {
      duration: 300,
      tension: 170,
      friction: 26,
    },
  });

  return (
    <animated.div
      className='bg-white p-6 rounded-2xl border border-gray-300 shadow-md'
      style={{ width: '660px', height: '407px', ...pageProps, ...style }}
    >
      <h5
        className='font-black text-default mb-14 text-center font-sans'
        style={{ fontSize: '32px', lineHeight: '35.2px' }}
      >
        Lütfen sigorta priminizi hesaplayabilmemiz için gereken risk
        parametrelerini girin.
      </h5>

      <div className='flex justify-between items-center'>
        <div>
          <p className='font-sans font-thin text-default mb-1 ml-4'>
            Net Tesis Bedeli
          </p>
          <OutlinedInput
            placeholder='1.000.000'
            value={facilityCost}
            onChange={handleFacilityCostChange}
            className='ml-4'
            style={{ height: '48px', borderRadius: '8px', width: '449px' }}
          />
        </div>
        <div>
          <p className='font-sans font-thin text-default mb-1 mr-4'>
            Döviz Kuru
          </p>

          <FormControl
            variant='outlined'
            style={{ marginRight: '16px', width: '115px' }}
          >
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={"dolar"}
              onChange={handleCurrencyChange}
              label='Döviz Kuru'
              inputProps={{
                name: 'your-select',
                id: 'your-select',
              }}
              style={{
                height: '48px',
                borderRadius: '8px',
              }}
            >
              <MenuItem value={'dolar'}>$ (DOLAR)</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className='flex justify-between'>
        <button
          className='
          flex justify-center items-center font-medium  mt-20
          rounded-lg focus:outline-none focus:shadow-outline-blue'
          style={{
            fontSize: '16px',
            width: '110px',
            height: '48px',
            color: '#0074DF',
            fontWeight: 600,
            marginTop: '72px',
          }}
          onClick={onHandleMap}
        >
          <div className='flex justify-center items-center'>
            <BsArrowLeftShort
              style={{
                width: '24px',
                height: '24px',
                marginRight: '1rem',
              }}
              className='inline-block'
            />
            <p
              style={{
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.02em',
                marginRight: '24px',
              }}
              className='font-sans'
            >
              Geri
            </p>
          </div>
        </button>
        <button
          className={`flex justify-center items-center
          text-white font-medium
          rounded-lg focus:outline-none focus:shadow-outline-blue 
          active:bg-blue-800 bg-[#003769] w-[145px] h-[48px] mt-[72px] ${!facilityCost && 'opacity-50 cursor-not-allowed'}`}
          onClick={handleFinancePage}
          disabled={!facilityCost}
        >
          <div className='flex justify-center items-center'>
            <p
              style={{
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.02em',
                marginLeft: '1rem',
              }}
              className='font-sans'
            >
              Hesapla
            </p>{' '}
            <BsArrowRightShort
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '1rem',
              }}
              className='inline-block'
            />
          </div>
        </button>
      </div>
    </animated.div>
  );
};

export default Finance;

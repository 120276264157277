import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

const LoadingScreen = ({ onHandleShowSummaryPage, style }) => {
  useEffect(() => {
    setTimeout(() => {
      onHandleShowSummaryPage();
    }, 3000);
  }, [onHandleShowSummaryPage]);

  return (
    <div
      className='bg-white p-6 rounded-2xl border border-gray-300 shadow-md'
      style={{ width: '660px', height: '358px', ...style }}
    >
      <h5
        className='font-black text-default mb-12 text-center font-sans'
        style={{ fontSize: '32px', lineHeight: '35.2px' }}
      >
        Sizin için en uygun sigorta planlarını hesaplıyoruz...
      </h5>
      <div className='justify-center text-center'>
        <CircularProgress style={{ width: '192px', height: '192px' }} />
      </div>
    </div>
  );
};

export default LoadingScreen;
